import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../../contexts/ApiContext.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';

const ContactCompany = () => {
    const { subscriptionStatus, companyContactEmail } = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex h-full flex-col">
                <div className="pb-4">
                    <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                        Contact the Company
                    </h1>
                </div>
                {subscriptionStatus ? (
                    <>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Have a concern or query? 
                        </p>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Reach out to the app owners at 
                            <a href={`mailto:${companyContactEmail}`} className="text-purple-900"> {companyContactEmail}</a>.
                        </p>
                    </>
                    
                ) : (
                    <a href={`mailto:${companyContactEmail}`} className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                        Please contact the app owners, they have no subscriptions active or paid.
                    </a>
                )}
            </div>
        </div>
    );
};

export default ContactCompany;
