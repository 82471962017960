import { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const UseCustomClaims = () => {
  const [user, setUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const auth = getAuth();

  // Function to fetch and update custom claims
  const updateCustomClaims = useCallback(async () => {
    if (auth.currentUser) {
      try {
        // Force token refresh to get the latest claims
        const tokenResult = await auth.currentUser.getIdTokenResult(true);
        setClaims(tokenResult.claims);
      } catch (error) {
        console.error("Error updating custom claims:", error);
      }
    }
  }, [auth]);

  useEffect(() => {
    // Function to fetch claims initially
    const fetchClaims = async () => {
      if (auth.currentUser) {
        try {
          // Get the ID token and decode it to access the claims
          const tokenResult = await auth.currentUser.getIdTokenResult();
          setClaims(tokenResult.claims);
        } catch (error) {
          console.error("Error fetching initial claims:", error);
        }
      }
    };

    // Fetch claims initially
    fetchClaims();

    // Set up an observer on the Firebase Auth object
    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      setUser(authUser);
      if (authUser) {
        await updateCustomClaims(); // Update claims when auth state changes
      }
    });

    return () => {
      unsubscribe(); // Cleanup subscription
    };
  }, [auth, updateCustomClaims]);

  return { claims, updateCustomClaims }; // Return the claims and the update function
};

export default UseCustomClaims;
