import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

import {
  createUserWithEmailAndPassword,
  getAuth,
  updateProfile
} from "firebase/auth";

import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from '../../firebase.js';
import { collection, query, where, getDocs, doc, updateDoc, addDoc } from "firebase/firestore"; 

import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Container } from '../../components/Container';
import { Button } from '../../components/Button.js';
import UseCustomClaims from '../../components/UseCustomClaims.js';


const createUserCompany = async (user) => {
  const functions = getFunctions();
  const createCompanyDocument = httpsCallable(functions, 'createCompanyDocument');

  createCompanyDocument({ iapticAppName: "", iapticSecretKey: "", stripePurchaserEmail: user.email})
    .then((result) => {
      // console.log(result.data.message); 
    })
    .catch((error) => {
      console.error("Error creating company document:", error);
    });
};

const PasswordSignUp = () => {
  const { claims, updateCustomClaims } = UseCustomClaims();
  const { userType } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // instantiate the auth service SDK
  const auth = getAuth();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
    if (name === "username") setUsername(value);
    if (name === "companyName") setCompanyName(value); 
  };


  const addAffiliateToCompany = async (username, email, companyName) => {
    const functions = getFunctions();
    const addAffiliateToCompany = httpsCallable(functions, 'addAffiliateToCompany');

    addAffiliateToCompany({ username: username, email: email, companyName: companyName})
      .then((result) => {
        if (result.data.message != "Affiliate added to company.") {
          setError(true);
          setErrorMessage(result.data.message);
        }


        // Continue login process...
      })
      .catch((error) => {
        console.error("Error creating company document:", error);
      });
  }

  const setAffiliateUserCustomClaims = async (email, companyName) => {
    const functions = getFunctions();
    const setCustomClaims = httpsCallable(functions, 'setCustomClaims');

    return setCustomClaims({ email, companyName: companyName });
  };

  // Handle user sign up with email and password
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (auth.currentUser !== null && username !== null && username !== "") {
        await updateProfile(auth.currentUser, {
          displayName: username
        });
      }

      if (userType === 'affiliate') {
        await addAffiliateToCompany(username, email, companyName);
        await setAffiliateUserCustomClaims(email, companyName);
        await updateCustomClaims();
      } else {
        createUserCompany(auth.currentUser, companyName)
      }

      navigate('/');
    } catch (err) {
      const errorMessage = err.message;
      const errorCode = err.code;

      setError(true);

      switch (errorCode) {
        case "auth/weak-password":
          setErrorMessage("The password is too weak.");
          break;
        case "auth/email-already-in-use":
          setErrorMessage(
            "This email address is already in use by another account."
          );
          break;
        case "auth/invalid-email":
          setErrorMessage("This email address is invalid.");
          break;
        case "auth/operation-not-allowed":
          setErrorMessage("Email/password accounts are not enabled.");
          break;
        default:
          setErrorMessage(errorMessage);
          break;
      }
    }
  };

  return (
    <div id="app">
      <Container>
        <Header />
      </Container>

      <div className="signupContainer flex flex-col items-center justify-center" style={{ minHeight: '65vh' }}>
        <div className='max-w-lg w-full px-4 pb-8'>
          <h1 className="pb-6 mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
            Sign Up
          </h1>
          <form className='signinContainer__box__form w-full max-w-lg mx-auto flex flex-col space-y-4' onSubmit={handleSubmit}>
           <input
              type='text'
              placeholder='Username'
              onChange={handleChange}
              name='username'
              value={username}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
            />
            <input
              type='email'
              placeholder='Email'
              onChange={handleChange}
              name='email'
              value={email}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500 w-full'
            />
            <input
              type='password'
              placeholder='Password'
              onChange={handleChange}
              name='password'
              value={password}
              className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
            />
            {userType === 'affiliate' && ( // Conditionally render the company name field
              <input
                type='text'
                placeholder='Company Name'
                onChange={handleChange}
                name='companyName'
                value={companyName}
                className='border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500'
              />
            )}
            <Button type='submit'>Sign Up</Button>
            {error && <p className='text-red-500'>{errorMessage}</p>}
          </form>

          <div className='signupContainer__box__login pt-4'>
            <p>
              Already have an account? <Link to='/signin' className="text-purple-600 cursor-pointer">Sign In</Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordSignUp;