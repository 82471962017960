import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const flattenPurchases = (data) => {
  return data.reduce((acc, user) => {
    return acc.concat(user.purchases);
  }, []);
};

const calculateEarnings = (purchases) => {
  const currentDate = new Date();
  let currentEarnings = 0;
  let expectedEarnings = 0;
  let projectedEarnings = 0;

  let currentSalesTotal = 0;
  let expectedSalesTotal = 0;
  let projectedSalesTotal = 0;

  purchases.forEach((purchase) => {
    const lastRenewalDate = new Date(purchase.lastRenewalDate);
    const expirationDate = new Date(purchase.expirationDate);

    if (
      lastRenewalDate.getMonth() === currentDate.getMonth() &&
      lastRenewalDate.getFullYear() === currentDate.getFullYear()
    ) {
      currentSalesTotal += purchase.quantity;
      currentEarnings += purchase.amountUSD !== null && purchase.amountUSD !== undefined ? purchase.amountUSD : 0;
    }

    if (expirationDate > currentDate && purchase.renewalIntent !== 'Lapse') {
      expectedSalesTotal += purchase.quantity;
      expectedEarnings += purchase.amountUSD !== null && purchase.amountUSD !== undefined ? purchase.amountUSD : 0;
    }

    if (expirationDate.getMonth() === currentDate.getMonth() + 1 && expirationDate.getFullYear() === currentDate.getFullYear()) {
      projectedSalesTotal += purchase.quantity;
      projectedEarnings += purchase.amountUSD !== null && purchase.amountUSD !== undefined ? purchase.amountUSD : 0;
    }
  });

  return {
    currentEarnings,
    expectedEarnings,
    projectedEarnings,
    currentSalesTotal,
    expectedSalesTotal,
    projectedSalesTotal,
  };
};

const AffiliateEarningsComponent = ({ data }) => {
  const [earnings, setEarnings] = useState({
    currentEarnings: 0,
    expectedEarnings: 0,
    projectedEarnings: 0,
    currentSalesTotal: 0,
    expectedSalesTotal: 0,
    projectedSalesTotal: 0,
  });

  useEffect(() => {
    const flattenedPurchases = flattenPurchases(data);
    const calculatedEarnings = calculateEarnings(flattenedPurchases);
    setEarnings(calculatedEarnings);
  }, [data]);

  // const chartData = [
  //   {
  //     name: 'Current Month',
  //     Earnings: earnings.currentEarnings,
  //     Sales: earnings.currentSalesTotal,
  //   },
  //   {
  //     name: 'Upcoming',
  //     Earnings: earnings.expectedEarnings,
  //     Sales: earnings.expectedSalesTotal,
  //   },
  //   {
  //     name: 'Next Month',
  //     Earnings: earnings.projectedEarnings,
  //     Sales: earnings.projectedSalesTotal,
  //   },
  // ];

  const chartData = [ // Demo data
    {
      name: 'Current Month',
      Earnings: 10, //earnings.currentEarnings,
      Sales: 2// earnings.currentSalesTotal,
    },
    {
      name: 'Upcoming',
      Earnings: 15, //earnings.expectedEarnings,
      Sales: 3, //earnings.expectedSalesTotal,
    },
    {
      name: 'Next Month',
      Earnings: 25, //earnings.projectedEarnings,
      Sales: 4 //earnings.projectedSalesTotal,
    },
  ];

  const legendPayload = [
    { value: 'Number of Sales', type: 'square', id: 'ID02', color: 'rgba(135, 68, 204, 1)' },
    { value: 'Earnings ($)', type: 'square', id: 'ID01', color: '#036704' }
  ];

  return (
    <div className="earnings-container">
        <h2 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl mb-4">
        Your Earnings ({new Date().toLocaleString('default', { month: 'long', year: 'numeric' })})
        </h2>
        <p className="mb-4 text-sm text-slate-600">
            "Current Month" shows current earnings and sales that you have already earned. 
            <br></br>
            "Upcoming" represents potential earnings if users don't unsubscribe before their payment date.
            <br></br>
            "Next Month" projects earnings and sales for the upcoming month.
        </p>
      {/* <div className="earnings-summary">
        <div>
          <p>Current Earnings This Month: ${earnings.currentEarnings.toFixed(2)}</p>
          <p>Expected Earnings This Month: ${earnings.expectedEarnings.toFixed(2)}</p>
          <p>Projected Earnings Next Month: ${earnings.projectedEarnings.toFixed(2)}</p>
        </div>
        <div>
          <p>Current Total Number of Sales: {earnings.currentSalesTotal}</p>
          <p>Expected Total Number of Sales: {earnings.expectedSalesTotal}</p>
          <p>Projected Total Number of Sales: {earnings.projectedSalesTotal}</p>
        </div>
      </div> */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend payload={legendPayload} />
          <Bar dataKey="Sales" fill="rgba(135, 68, 204, 1)" />
          <Bar dataKey="Earnings" fill="#036704" />
       
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AffiliateEarningsComponent;
