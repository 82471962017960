// src/components/PrivateAffiliateRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';

const PrivateAffiliateRoute = ({ element }) => {
    const { user } = useAuth();
    const [isAffiliate, setIsAffiliate] = useState(null);

    useEffect(() => {
        if (user) {
            const auth = getAuth();
            // Fetch the current user's ID token and check for 'affiliate' claim
            auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (idTokenResult.claims.affiliate) {
                        setIsAffiliate(true);
                    } else {
                        setIsAffiliate(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user claims", error);
                    setIsAffiliate(false);
                });
        } else {
            setIsAffiliate(false);
        }
    }, [user]);  // Dependency array ensures effect runs only when `user` changes

    if (isAffiliate === null) {
        return <div></div>; // or any other loading component
    }

    return isAffiliate ? element : <Navigate to="/" replace />;
};

export default PrivateAffiliateRoute;
