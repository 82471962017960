import React, { useState, useContext, useEffect } from 'react';
import { Button } from '../components/Button.js';
import { ApiContext } from '../contexts/ApiContext.js';
import { db } from '../firebase.js';
import FetchUserAndFirebaseDocsOnAuth from '../components/fetchFirebaseDocsOnAuth.js';
import { collection, getDoc, updateDoc, doc } from 'firebase/firestore'; // Import firestore functions
import { ClipboardDocumentListIcon, Cog6ToothIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const steps = [
  {
    title: "Step 1/3",
    header: "Deep Linking",
    content: Step1Content,
    icon: ClipboardDocumentListIcon,
  },
  {
    title: "Step 2/3",
    header: "In-App Purchase Verification",
    content: Step2Content,
    icon: Cog6ToothIcon,
  },
  {
    title: "Step 3/3",
    header: "Confirm Your Settings",
    content: Step3Content,
    icon: CheckCircleIcon,
  }
];

function Step1Content() {
  return (
    <>
        <p className="mb-4">
            Deep links allow affiliates to direct potential customers to your app. We integrate with third-party technologies you already use for a seamless experience.
        </p>
        <p className="mb-4">
            Select your preferred deep linking platform from the options below:
        </p>
        <div className="not-prose mt-4 grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-2 xl:max-w-none xl:grid-cols-2 dark:border-white/5 mb-4 md:mb-10">
            <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/branch" target="_blank">
                <div className="flex-auto p-7 border">
                    <h3 className="text-sm font-semibold text-zinc-900">
                        Branch (Recommended)
                    </h3>
                    <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                        One of the most popular deep linking platforms.
                    </p>
                    <div className="mt-4">
                        <div
                            className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                            href={"docs.insertaffiliate.com/branch"}
                            variant="text"
                            arrow="right"
                        >
                            Setup Guide
                            <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </>
  );
}

function Step2Content({ localIapticAppName, setLocalIapticAppName, localIapticSecretKey, setLocalIapticSecretKey, showIapticSecret, toggleSecretIapticVisibility }) {
    return (
      <>
        <p className="mb-4">
          Insert Affiliate uses receipt verification to accurately track sales and profits. We integrate with third-party technologies you already use for a seamless experience.
        </p>
        <div className="not-prose grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 xl:max-w-none xl:grid-cols-2 dark:border-white/5 mb-8">
          <a className="flex flex-row-reverse gap-6 mt-4" href="https://docs.insertaffiliate.com/branch" target="_blank" rel="noopener noreferrer">
            <div className="flex-auto p-7 border">
              <h3 className="text-sm font-semibold text-zinc-900">
                Iaptic Setup
              </h3>
              <p className="mt-1 text-sm text-zinc-600 dark:text-zinc-400">
                Service for validating in-app purchase and subscription receipts.
              </p>
              <div className="mt-4">
                <div
                  className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition text-purple-500 hover:text-purple-600 dark:text-purple-300 dark:hover:text-purple-500"
                  href={"docs.insertaffiliate.com/iaptic"}
                  variant="text"
                  arrow="right"
                >
                    Setup Guide
                <svg viewBox="0 0 20 20" fill="none" aria-hidden="true" className="mt-0.5 h-5 w-5 relative top-px -mr-1"><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="m11.5 6.5 3 3.5m0 0-3 3.5m3-3.5h-9"></path></svg>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="grid grid-cols-1 gap-4 md:gap-6 sm:grid-cols-2 mb-4 md:mb-8">
            <div>
                <label htmlFor="iapticAppName" className="mb-3 block text-sm font-medium text-gray-700">
                    Iaptic App Name:
                </label>
                <div>
                <input
                    type="text"
                    id="iapticAppName"
                    value={localIapticAppName}
                    onChange={(e) => setLocalIapticAppName(e.target.value)}
                    className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                />
                </div>
            </div>
            <div>
                <label htmlFor="iapticSecretKey" className="mb-3 block text-sm font-medium text-gray-700">
                    Iaptic Secret:
                </label>
                <div className="relative">
                    <input
                        type={showIapticSecret ? 'text' : 'password'}
                        id="iapticSecretKey"
                        value={localIapticSecretKey}
                        onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                    <button
                        type="button"
                        onClick={toggleSecretIapticVisibility}
                        className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                    >
                        {showIapticSecret ? 'Hide' : 'Show'}
                    </button>
                </div>
            </div>
        </div>
      </>
    );
}

function Step3Content({ localIapticAppName, setLocalIapticAppName, localIapticSecretKey, setLocalIapticSecretKey, showIapticSecret, toggleSecretIapticVisibility, localCompanyName, setLocalCompanyName, localCompanyContactEmail, setLocalCompanyContactEmail, localCompanyAffiliatePaymentPercentage, setLocalCompanyAffiliatePaymentPercentage }) {
    return (
        <>
            <p className="mb-4">Please confirm your settings below:</p>
            <div className="grid grid-cols-1 gap-4 md:gap-6 sm:grid-cols-2 mb-4 md:mb-8">
                <div>
                    <label htmlFor="companyName" className="mb-3 block text-sm font-medium text-gray-700">
                        Company Name:
                    </label>
                    <input
                        type="text"
                        id="companyName"
                        value={localCompanyName}
                        onChange={(e) => setLocalCompanyName(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="companyContactEmail" className="mb-3 block text-sm font-medium text-gray-700">
                        Contact Email (for Affiliates):
                    </label>
                    <input
                        type="email"
                        id="companyContactEmail"
                        value={localCompanyContactEmail}
                        onChange={(e) => setLocalCompanyContactEmail(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="companyAffiliatePaymentPercentage" className="mb-3 block text-sm font-medium text-gray-700">
                        Affiliate Payment Percentage:
                    </label>
                    <input
                        type="number"
                        id="companyAffiliatePaymentPercentage"
                        value={localCompanyAffiliatePaymentPercentage}
                        onChange={(e) => setLocalCompanyAffiliatePaymentPercentage(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                </div>
                <div>
                    <label htmlFor="iapticAppName" className="mb-3 block text-sm font-medium text-gray-700">
                        Iaptic App Name:
                    </label>
                    <div>
                    <input
                        type="text"
                        id="iapticAppName"
                        value={localIapticAppName}
                        onChange={(e) => setLocalIapticAppName(e.target.value)}
                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                    />
                    </div>
                </div>
                <div>
                    <label htmlFor="iapticSecretKey" className="mb-3 block text-sm font-medium text-gray-700">
                        Iaptic Secret:
                    </label>
                    <div className="relative">
                        <input
                            type={showIapticSecret ? 'text' : 'password'}
                            id="iapticSecretKey"
                            value={localIapticSecretKey}
                            onChange={(e) => setLocalIapticSecretKey(e.target.value)}
                            className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                        />
                        <button
                            type="button"
                            onClick={toggleSecretIapticVisibility}
                            className="absolute inset-y-0 right-0 px-3 flex items-center text-sm font-medium text-gray-700"
                        >
                            {showIapticSecret ? 'Hide' : 'Show'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
  }

export function OnboardingModal({ type, onClose }) {
    const {
        iapticAppName,
        setIapticAppName,
        iapticSecretKey,
        setIapticSecretKey,
        companyName,
        setCompanyName,
        companyContactEmail,
        setCompanyContactEmail,
        companyAffiliatePaymentPercentage,
        setCompanyAffiliatePaymentPercentage
    } = useContext(ApiContext);
    const [showIapticSecret, setShowIapticSecret] = useState(false);
    const [localCompanyName, setLocalCompanyName] = useState(companyName || ""); // Ensure a default value
    const [localCompanyContactEmail, setLocalCompanyContactEmail] = useState(companyContactEmail || "");
    const [localCompanyAffiliatePaymentPercentage, setLocalCompanyAffiliatePaymentPercentage] = useState(companyAffiliatePaymentPercentage || 5); // Ensure a default value
    const [localIapticAppName, setLocalIapticAppName] = useState(iapticAppName || ""); // Ensure a default value
    const [localIapticSecretKey, setLocalIapticSecretKey] = useState(iapticSecretKey || "");

    const [currentStep, setCurrentStep] = useState(0);
   
    // Update local when remote is retrieved
    useEffect(() => {
        if (companyName !== '' && companyName !== localCompanyName) {
        setLocalCompanyName(companyName);
        }
    }, [companyName]);
    useEffect(() => {
        if (companyContactEmail !== '' && companyContactEmail !== localCompanyContactEmail) {
        setLocalCompanyContactEmail(companyContactEmail);
        }
    }, [companyContactEmail]);
    useEffect(() => {
        if (iapticAppName !== '' && iapticAppName !== localIapticAppName) {
        setLocalIapticAppName(iapticAppName);
        }
    }, [iapticAppName]);
    useEffect(() => {
        if (iapticSecretKey !== '' && iapticSecretKey !== localIapticSecretKey) {
        setLocalIapticSecretKey(iapticSecretKey);
        }
    }, [iapticSecretKey]);
    useEffect(() => {
        if (companyAffiliatePaymentPercentage !== '' && companyAffiliatePaymentPercentage !== localCompanyAffiliatePaymentPercentage) {
        setLocalCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
        }
    }, [companyAffiliatePaymentPercentage]);

    const user = FetchUserAndFirebaseDocsOnAuth();

    const updateAndSaveSettings = async ({ companyName, companyContactEmail, iapticAppName, iapticSecretKey, companyAffiliatePaymentPercentage }) => {
        // Assuming your Firestore collection is named 'copanies'
        const companiesRef = collection(db, 'Companies');

        const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID

        const privateRef = collection(userDocRef, 'Private');
        const privateDocRef = doc(privateRef, 'Credentials');

        try {
            const userDocSnapshot = await getDoc(userDocRef);
            const oldCompanyName = userDocSnapshot.data().companyName;

            // console.log(
            //   'Settings Updated - Old Company Name:',
            //   oldCompanyName,
            //   'New Company Name:',
            //   companyName,
            //   'Iaptic App Name:',
            //   iapticAppName,
            //   'Iaptic Secret Key:',
            //   iapticSecretKey,
            //   'companyAffiliatePaymentPercentage:',
            //   companyAffiliatePaymentPercentage
            // );

            // Update the Firestore document with the new settings
            await updateDoc(userDocRef, {
            companyName: companyName,
            companyContactEmail: companyContactEmail,
            companyAffiliatePaymentPercentage: companyAffiliatePaymentPercentage
            });

            await updateDoc(privateDocRef, {
            iapticAppName: iapticAppName,
            iapticSecretKey: iapticSecretKey
            });

            setCompanyName(companyName);
            setCompanyContactEmail(companyContactEmail);
            setIapticAppName(iapticAppName);
            setIapticSecretKey(iapticSecretKey);
            setCompanyAffiliatePaymentPercentage(companyAffiliatePaymentPercentage);
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    const toggleSecretIapticVisibility = () => {
        setShowIapticSecret(!showIapticSecret);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateAndSaveSettings({
            companyName: localCompanyName,
            companyContactEmail: localCompanyContactEmail,
            iapticAppName: localIapticAppName,
            iapticSecretKey: localIapticSecretKey,
            companyAffiliatePaymentPercentage: localCompanyAffiliatePaymentPercentage
        });
    };

    const handleNextStep = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            updateAndSaveSettings({
                companyName: localCompanyName,
                companyContactEmail: localCompanyContactEmail,
                iapticAppName: localIapticAppName,
                iapticSecretKey: localIapticSecretKey,
                companyAffiliatePaymentPercentage: localCompanyAffiliatePaymentPercentage
            });
            onClose();
        }
    };

    const StepContent = steps[currentStep].content;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
              <div className="bg-white w-full max-w-4xl h-full md:h-4/5 rounded-lg shadow-lg flex flex-col md:flex-row md:m-4">
                {/* Left side */}
                <div className="bg-purple-700 w-full md:w-1/3 p-4 md:p-8 flex flex-col items-center">
                    <div className="mt-2 flex flex-col w-full">
                        <h1 className="text-white text-3xl md:mb-8">
                        Onboarding Steps
                        </h1>
                        <div className="flex md:flex-col flex-row md:items-start justify-between w-full pt-5">
                            {steps.map((step, index) => (
                                <div
                                    key={index}
                                    className={`relative flex md:flex-row flex-col items-center mb-4 md:mb-8 cursor-pointer ${index === currentStep ? 'text-white' : 'text-gray-400'}`}
                                    onClick={() => setCurrentStep(index)}
                                >
                                    <step.icon className={`w-6 h-6 ${index === currentStep ? 'text-white' : 'text-gray-400'}`} />
                                    <div className="md:ml-4 md:text-left text-center">
                                        <h4 className="text-lg font-bold">Step {index + 1}</h4>
                                        <p className="text-sm">{step.header}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right side */}
                <div className="w-full md:w-2/3 p-4 md:p-8 overflow-y-auto">
                <div className="mb-4">
                    <h3 className="text-sm text-gray-500">{steps[currentStep].title}</h3>
                    <h2 className="text-xl font-semibold text-gray-900">{steps[currentStep].header}</h2>
                </div>
                {currentStep === 1 ? (
                    <Step2Content
                        localIapticAppName={localIapticAppName}
                        setLocalIapticAppName={setLocalIapticAppName}
                        localIapticSecretKey={localIapticSecretKey}
                        setLocalIapticSecretKey={setLocalIapticSecretKey}
                        showIapticSecret={showIapticSecret}
                        toggleSecretIapticVisibility={toggleSecretIapticVisibility}
                    />
                ) : currentStep === 2 ? (
                    <Step3Content
                        localCompanyName={localCompanyName}
                        setLocalCompanyName={setLocalCompanyName}
                        localCompanyContactEmail={localCompanyContactEmail}
                        setLocalCompanyContactEmail={setLocalCompanyContactEmail}
                        localCompanyAffiliatePaymentPercentage={localCompanyAffiliatePaymentPercentage}
                        setLocalCompanyAffiliatePaymentPercentage={setLocalCompanyAffiliatePaymentPercentage}
                        localIapticAppName={localIapticAppName}
                        setLocalIapticAppName={setLocalIapticAppName}
                        localIapticSecretKey={localIapticSecretKey}
                        setLocalIapticSecretKey={setLocalIapticSecretKey}
                        showIapticSecret={showIapticSecret}
                        toggleSecretIapticVisibility={toggleSecretIapticVisibility}
                    />
                ) : (
                    <StepContent />
                )}
                <Button onClick={handleNextStep}>{currentStep < steps.length - 1 ? 'Continue' : 'Finish'}</Button>
                </div>
            </div>
        </div>
    );
}
