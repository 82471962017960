import React, { useState, useContext, useEffect } from 'react';
import SignOut from '../auth/SignOut.js';
import { Container } from '../../components/Container.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { ApiContext } from '../../contexts/ApiContext.js';

import UseCustomClaims from '../../components/UseCustomClaims.js';

const AffiliateSettings = () => {
  const claims = UseCustomClaims();
  const {companyName, deepLink, affiliateName, companyAffiliatePaymentPercentage } = useContext(ApiContext);
  const user = FetchUserAndFirebaseDocsOnAuth();

  return (
    <div>
      <Container>
        <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
          <div className="pb-4">
            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
              Affiliate Settings
            </h1>
            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
              This is all we need to get started. 
            </p>
          </div>
          {claims && ( // Add null check for claims
            <div>
              <div>
                <label
                  htmlFor="deepLink"
                  className="mt-6 mb-3 block text-sm font-medium text-gray-700"
                >
                  Deep (Referral) Link:
                </label>
                <div
                  id="deepLink"
                  className="block w-full bg-gray-50 px-3 py-2 text-gray-900 rounded-md border border-gray-200 text-sm"
                >
                  {deepLink}
                </div>
              </div>
              <div>
                <label
                  htmlFor="affiliateName"
                  className="mt-6 mb-3 block text-sm font-medium text-gray-700"
                >
                  User Name:
                </label>
                <div
                  id="affiliateName"
                  className="block w-full bg-gray-50 px-3 py-2 text-gray-900 rounded-md border border-gray-200 text-sm"
                >
                  {affiliateName}
                </div>
              </div>
              <div>
                <label
                  htmlFor="companyID"
                  className="mb-3 mt-6 block text-sm font-medium text-gray-700"
                >
                  Company ID:
                </label>
                <div
                  id="companyID"
                  className="block w-full bg-gray-50 px-3 py-2 text-gray-900 rounded-md border border-gray-200 text-sm"
                >
                  {claims.claims?.companyId}
                </div>
              </div>
              <div>
                <label
                  htmlFor="companyName"
                  className="mt-6 mb-3 block text-sm font-medium text-gray-700"
                >
                  Company Name:
                </label>
                <div
                  id="companyName"
                  className="block w-full bg-gray-50 px-3 py-2 text-gray-900 rounded-md border border-gray-200 text-sm"
                >
                  {companyName}
                </div>
              </div>
              <div>
                <label
                  htmlFor="companyAffiliatePaymentPercentage"
                  className="mt-6 mb-3 block text-sm font-medium text-gray-700"
                >
                  Affiliate Payment Percentage (the percentage of the generated revenue that you will earn)
                </label>
                <div
                  id="companyAffiliatePaymentPercentage"
                  className="block w-full bg-gray-50 px-3 py-2 text-gray-900 rounded-md border border-gray-200 text-sm"
                >
                  {companyAffiliatePaymentPercentage}
                </div>
              </div>
            </div>             
          )}
          <div className="pt-6">
            <SignOut />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AffiliateSettings;
