import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';
import { AdminFooter } from './AdminFooter';
import { AffiliateFooter } from './AffiliateFooter';
import { UnauthFooter } from './UnauthFooter';

export function Footer() {
  const { user } = useAuth();
  const [isAffiliate, setIsAffiliate] = useState(null);

  useEffect(() => {
    if (user) {
      const auth = getAuth();
      auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          setIsAffiliate(idTokenResult.claims.affiliate || false);
        })
        .catch((error) => {
          console.error("Error fetching user claims", error);
          setIsAffiliate(false);
        });
    } else {
      setIsAffiliate(false);
    }
  }, [user]);

  return user ? isAffiliate ? <AffiliateFooter /> : <AdminFooter /> : <UnauthFooter />;
}
