import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';
import { AdminHeader } from './AdminHeader';
import { AffiliateHeader } from './AffiliateHeader';
import { UnauthHeader } from './UnauthHeader';

export function Header() {
  const { user } = useAuth();
  const [isAffiliate, setIsAffiliate] = useState(null);

  useEffect(() => {
    if (user) {
      const auth = getAuth();
      auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          setIsAffiliate(idTokenResult.claims.affiliate || false);
        })
        .catch((error) => {
          console.error("Error fetching user claims", error);
          setIsAffiliate(false);
        });
    } else {
      setIsAffiliate(false);
    }
  }, [user]);


  // Return AffiliateHeader if isAffiliate is true, otherwise return AdminHeader
  return user ? isAffiliate ? <AffiliateHeader /> : <AdminHeader /> : <UnauthHeader />;
}
