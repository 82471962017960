import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { firebaseConfig } from './firebase.js'; //app, analytics, 
import PasswordSignIn from './app/auth/PasswordSignIn.js';
import PasswordSignUp from './app/auth/PasswordSignUp.js';
import AffiliateOrAppOwnerSelection from './app/auth/AffiliateOrAppOwnerSelection';
import AffiliateSearch from './app/affiliateSearch/AffiliateSearch.js'
import CreateAffiliate from './app/createAffiliate/CreateAffiliate.js'
import Affiliate from './app/affiliate/Affiliate.js'
import AffiliateSettings from './app/affiliateSettings/AffiliateSettings.js'
import AffiliateSelfSearch from './app/affiliateSelfSearch/AffiliateSelfSearch.js'
import { Provider } from 'react-redux'; // Import the Provider
// import store from './store'; // Import your Redux store

import { ApiProvider } from './contexts/ApiContext';
import ShowAllAffiliates from './app/showAllAffiliates/ShowAllAffiliates.js';
import Settings from './app/settings/Settings.js';
import Help from './app/help/Help.js';

import PrivateAdminRoute from './components/PrivateAdminRoute';
import PublicRoute from './components/PublicRoute';
import PrivateAffiliateRoute from './components/PrivateAffiliateRoute';
import PrivateBothRoute from './components/PrivateBothRoute';
import UserProfile from './components/UserProfile';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

import DashboardLayout from './components/DashboardLayout';
import Billing from './app/billing/Billing.js';
import ContactCompany from './app/contactCompany/ContactCompany.js';
import * as Sentry from "@sentry/react";

import usePageTracking from './usePageTracking.js'; // Import the custom hook



// Initialize Firebase (you can remove this part if you've already done it in firebase.js)
const firebaseApp = initializeApp(firebaseConfig);

// eslint-disable-next-line
const firebaseAnalytics = getAnalytics(firebaseApp);

Sentry.init({
  dsn: "https://8fb9c951bab5f5b6657388e7dac26f16@o4507386027835392.ingest.de.sentry.io/4507386159890512",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const AppWrapper = () => {
  usePageTracking(); // Use the custom hook

  return (
    <Routes>
      {/* Auth Routes */}
      <Route path="/signin" element={<PublicRoute element={<PasswordSignIn />} />} />
      <Route path="/signup" element={<PublicRoute element={<AffiliateOrAppOwnerSelection />} />} />
      <Route path="/signup/:userType" element={<PasswordSignUp />} />

      {/* Routes for both as long as they're logged in */}
      <Route path="/" element={<PrivateBothRoute element={<DashboardLayout><App /></DashboardLayout>} />} />
      <Route path="/profile" element={<PrivateBothRoute element={<DashboardLayout><UserProfile /></DashboardLayout>} />} />
      <Route path="/help" element={<PrivateBothRoute element={<DashboardLayout><Help /></DashboardLayout>} />} />

      {/* Admin Routes */}
      <Route path="/affiliates" element={<PrivateAdminRoute element={<DashboardLayout><ShowAllAffiliates /></DashboardLayout>} />} />
      <Route path="/affiliate-search" element={<PrivateAdminRoute element={<DashboardLayout><AffiliateSearch /></DashboardLayout>} />} />
      <Route path="/create-an-affiliate" element={<PrivateAdminRoute element={<DashboardLayout><CreateAffiliate /></DashboardLayout>} />} />
      <Route path="/profile" element={<PrivateAdminRoute element={<DashboardLayout><UserProfile /></DashboardLayout>} />} />
      <Route path="/settings" element={<PrivateAdminRoute element={<DashboardLayout><Settings /></DashboardLayout>} />} />
      <Route path="/billing" element={<PrivateAdminRoute element={<DashboardLayout><Billing /></DashboardLayout>} />} />

      {/* Affiliate Routes */}
      <Route path="/affiliate" element={<PrivateAffiliateRoute element={<DashboardLayout><Affiliate /></DashboardLayout>} />} />
      <Route path="/contact-company" element={<PrivateAffiliateRoute element={<DashboardLayout><ContactCompany /></DashboardLayout>} />} />
      <Route path="/affiliate-self-search" element={<PrivateAffiliateRoute element={<DashboardLayout><AffiliateSelfSearch /></DashboardLayout>} />} />
      <Route path="/affiliate-settings" element={<PrivateAffiliateRoute element={<DashboardLayout><AffiliateSettings /></DashboardLayout>} />} />
    </Routes>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <Provider store={store}>
  <Router>
    <AuthProvider>
      <ApiProvider>
        <AppWrapper />
      </ApiProvider>
    </AuthProvider>
  </Router>
  // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
