import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container';
// import { db } from '../../firebase';
// import { collection, doc, getDocs } from 'firebase/firestore';
import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js'
import { getAuth } from "firebase/auth";
import UseCustomClaims from '../../components/UseCustomClaims.js';
import AffiliateEarningsComponent from '../../components/AffiliateEarningsComponent.js';

const AffiliateHomeDashboard = () => {
    const auth = getAuth();
    const claims = UseCustomClaims();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const {affiliateName, apiUrl} = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [purchasesData, setPurchasesData] = useState([]);
    const [error, setError] = useState(null); // State for handling errors


    useEffect(() => {
        if (claims && claims.companyId && affiliateName && user) {
            searchAnAffiliate();
        }
    }, [claims, affiliateName, user]);

    const searchAnAffiliate = () => {
        setSearchExecuted(true);
        setError(null); // Clear any previous errors

        // Set start and end dates to the current month as default
        setStartDate('2024-05-01'); // TODO: set to this month
        setEndDate('2024-05-31'); // TODO: set to this month

        if (affiliateName === '') {
            setError("Affiliate name is required to fetch data.");
            return;
        } else {
            // returnCustomerPurchasesBetweenDates -- this has a different endpoint!

            const start = startDate || '2021-01-01';
            const end = endDate || new Date().toISOString().slice(0, 10);
    
            fetch(`${apiUrl}/affiliateSearchSelfWithDates/${claims?.companyId}/${affiliateName}/${start}/${end}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching data: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    setPurchasesData(data);
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch data. If this error persists, please contact support.");
                });
        }
    };

    return (
        <div>
            <Container>
                <div className="flex flex-col ">
                    <div className="pb-4">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Welcome back!
                        </h1>
                        <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                            Here's your quick status update at a glance.
                        </p>
                    </div>
                    {error && (
                        <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                            {error}
                        </div>
                    )}
                    <div>
                        {purchasesData.length > 0 ? (
                            <AffiliateEarningsComponent data={purchasesData} />
                        ) : (
                            !error && searchExecuted && (
                                <p>No data available for the selected criteria.</p>
                            )
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default AffiliateHomeDashboard;