import { signOut, getAuth } from "firebase/auth";
import React from 'react';
import { Button } from '../../components/Button.js';

const Home = () => {
 // Instantiate the auth service SDK
 const auth = getAuth();

  return (
    <section className='home'>
      <div className='home__container'>
        <Button
          type="button"
          color="red"
          onClick={() => signOut(auth)}
        >
          Sign Out
        </Button>   
      </div>
    </section>
  );
};

export default Home;