import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';
import { Container } from '../../components/Container';

const AffiliateOrAppOwnerSelection = () => {
  const navigate = useNavigate();

  return (
    <div id="app">
        <Container>
            <Header />
        </Container>
        <div className="selectionContainer flex flex-col items-center justify-center" style={{ minHeight: '65vh' }}>
            <h1 className="pb-6 mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight text-slate-900 sm:text-5xl">
                Sign Up
            </h1>
            <div className="flex space-x-4">
                <button
                onClick={() => navigate('/signup/affiliate')}
                className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500"
                >
                I'm an Affiliate
                </button>
                <button
                onClick={() => navigate('/signup/app-owner')}
                className="border border-gray-300 rounded-md px-6 py-3 focus:outline-none focus:border-blue-500"
                >
                I'm an App Owner
                </button>
            </div>
        </div>
        <Footer />
    </div>
  );
};

export default AffiliateOrAppOwnerSelection;
