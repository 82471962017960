import React from 'react';
import { Container } from '../../components/Container.js';
import { Button } from '../../components/Button.js';
import {
  EnvelopeIcon
} from '@heroicons/react/24/outline';

const Help = () => {
  return (
    <div>
      <Container>
        <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
          <div className="pb-4">
            <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
              Help & Support
            </h1>
            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
              Welcome to the help and support page. We are here to help you with any questions or issues you may have.
            </p>
            <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
              Most questions can be answered by 
              <a
                href="https://docs.insertaffiliate.com"
                target="_blank"
                className="text-purple-600 hover:underline pl-1">
                 reading the docs
              </a>.
            </p>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold text-slate-800">Contact Us</h2>
            <div className="mt-4 space-y-4">
              <div className="flex items-center space-x-3">
                <EnvelopeIcon className="h-6 w-6 text-purple-600" aria-hidden="true" />
                <a href="mailto:michael@insertaffiliate.com" className="text-lg text-purple-600 hover:underline">
                  michael@insertaffiliate.com
                </a>
              </div>
            </div>
          </div>

          <div className="mb-8">
            <h2 className="text-xl font-semibold text-slate-800">Frequently Asked Questions</h2>
            <div className="mt-4 space-y-4">
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  What is in app purchase based affiliate marketing, and how does it work?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Affiliate marketing is a performance-based marketing strategy where businesses reward affiliates for driving sales through in app purchases within their iOS or Android app. Affiliates earn a commission for each successful referral they generate through their marketing efforts.
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  What types of businesses can benefit from affiliate marketing?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Virtually any business with a mobile app that contains in app purchases. Whether you're a small startup or a large enterprise, affiliate marketing offers a scalable and cost-effective way to drive sales and revenue growth.
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  How do I track affiliate-generated sales and commissions?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Our platform provides robust tracking and reporting features that allow you to monitor affiliate activities in near real-time. You can track clicks, conversions, and commissions earned by each affiliate through a comprehensive dashboard, ensuring transparency and accuracy in performance tracking.
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  How can affiliate marketing benefit my business?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Affiliate marketing offers several benefits, including increased brand exposure, expanded reach to new audiences, cost-effective advertising, and improved sales performance. By leveraging affiliates, businesses can tap into a vast network of marketers to promote their products or services efficiently.
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  How do I set competitive commission rates for my affiliates?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Setting competitive commission rates involves balancing your budget with enticing incentives for affiliates. Researching industry standards and analyzing your app's profitability can help determine appropriate rates. Additionally, consider offering performance-based incentives to motivate affiliates further. We might be able to help with this, if you need assistance reach out to us!
                </div>
              </details>
              <details className="group">
                <summary className="cursor-pointer text-lg text-slate-700 group-hover:text-purple-600">
                  What marketing materials should I provide to my affiliates?
                </summary>
                <div className="mt-2 text-base text-slate-600">
                  Provide your affiliates with a range of marketing materials to support their promotional efforts. This may include banners, social media graphics, email templates, and landing page content. Ensure that these materials align with your brand identity and messaging to maintain consistency across all channels. We might be able to help with this, if you need assistance reach out to us!
                </div>
              </details>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Help;
