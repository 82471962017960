// src/components/PrivateAdminRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';

const PrivateAdminRoute = ({ element }) => {
    const { user } = useAuth();
    const [isAffiliate, setIsAffiliate] = useState(null);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);

    useEffect(() => {
        if (user) {
           
            const auth = getAuth();
            // Fetch the current user's ID token and check for 'affiliate' claim
            auth.currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    if (idTokenResult.claims.affiliate) {
                        setIsAffiliate(true);
                    } else {
                        setIsAffiliate(false);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user claims", error);
                    setIsAffiliate(false);
                });
        } else {
            setIsUserLoggedIn(false);
            setIsAffiliate(false);
        }
    }, [user]);

    if (isAffiliate === null) {
        return <div></div>;
    }

    return (isAffiliate === false && isUserLoggedIn) ? element : <Navigate to="/signin" replace />;
};

export default PrivateAdminRoute;
