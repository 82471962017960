// used to check if the user is Authenticated before allowing access, if not it'll redirect to signin
// src/components/PubicRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const PublicRoute = ({ element }) => {
    const { user } = useAuth();

    // Return the user is authenticated, otherwise redirect to '/'
    // For if the user is authenticated, they can't access the signin or signup page
    return user ? <Navigate to="/" replace /> : element;
};

export default PublicRoute;
