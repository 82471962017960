// src/components/PrivateAdminRoute.js
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getAuth } from 'firebase/auth';

const PrivateBothRoute = ({ element }) => {
    const { user } = useAuth();
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(true);

    useEffect(() => {
        if (user) {
            setIsUserLoggedIn(true);
        } else {
            setIsUserLoggedIn(false);
        }
    }, [user]);  // Dependency array ensures effect runs only when `user` changes

    if (isUserLoggedIn === null) {
        return <div></div>; // or any other loading component
    }

    return isUserLoggedIn ? element : <Navigate to="/signin" replace />;
};

export default PrivateBothRoute;
