
import logo from '../images/logos/insert-affiliate-logo-white.png'
export function Logo(props) {
  return (
    <div>
      <img
        src={logo}
        alt="Insert Affiliate Logo"
        className="w-logo"
      ></img>
    </div>
  )
}
