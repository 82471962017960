'use client'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { Logo } from './Logo'
import { Link } from 'react-router-dom';

function MobileNavLink({
  href,
  children,
}) {
  return (
    <a href={href} className="block w-full p-2">
      {children}
    </a>
  )
}

function MobileNavIcon({ open }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  )
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <Link to="/affiliate-self-search">See My Earnings</Link>
            <Link to="/affiliate-settings">Settings</Link>
            <hr className="m-2 border-slate-300/40" />
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function AffiliateHeader() {
  return (
    <header className="py-10">
      <div className="mx-auto max-w-7xl ">
        <nav className="relative z-50 flex justify-between items-center">
            {/* <div className="flex items-center md:gap-x-12"> */}
              <a href="/" aria-label="Home">
                <Logo className="h-10 w-auto" />
              </a>
              <div className="hidden md:flex md:gap-x-12">
              <Link to="/affiliate-self-search">See My Earnings</Link>
                <Link to="/affiliate-settings">Settings</Link>
              </div>
            {/* </div> */}
            <div className="md:hidden flex items-center gap-x-5 md:gap-x-8">
              <div className="-mr-1 md:hidden">
                <MobileNavigation />
              </div>
            </div>
          </nav>
      </div>
    </header>
  )
}
