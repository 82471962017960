import React from 'react';
import {
  UserIcon
} from '@heroicons/react/24/outline'


const Avatar = ({ displayName }) => {
  const initials = displayName ? displayName.substring(0, 2).toUpperCase() : '';
  
  return (
    <div className="h-8 w-8 rounded-full bg-purple-200 flex items-center justify-center border border-gray-300">
      {initials ? (
        <span className="text-black font-bold text-sm">{initials}</span>
      ) : (
        <UserIcon className="h-6 w-6 text-slate-500" />
      )}
    </div>
  );
};

export default Avatar;
