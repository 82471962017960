import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { updateProfile, updatePassword, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'; // Import necessary functions
import { toast } from 'react-toastify';
import { Button } from '../components/Button.js';

const UserProfile = () => {
  const { user } = useAuth();
  const [displayName, setDisplayName] = useState(user.displayName || '');
  const [photoURL, setPhotoURL] = useState(user.photoURL || '');
  const [loading, setLoading] = useState(false);
  const [newEmail, setNewEmail] = useState('');

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleUpdateProfile = async () => {
    setLoading(true);
    try {
      await updateProfile(user, { displayName, photoURL });
      toast.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error.message);
      toast.error('Failed to update profile. Please try again later.');
    }
    setLoading(false);
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      // Reauthenticate the user with their current password
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      
      // Check if new password and confirm password match
      if (newPassword !== confirmPassword) {
        throw new Error('New password and confirm password do not match');
      }

      // Update password
      await updatePassword(user, newPassword);
      toast.success('Password changed successfully');
    } catch (error) {
      console.error('Error changing password:', error.message);
      toast.error('Failed to change password. Please try again later.');
    }
    setLoading(false);
  };

  const handleChangeEmail = async () => {
    setLoading(true);
    try {
      // Reauthenticate the user with their current password
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update email
      await updateEmail(user, newEmail);
      toast.success('Email updated successfully');
    } catch (error) {
      console.error('Error updating email:', error.message);
      toast.error('Failed to update email. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="pb-8 mx-auto" style={{ minHeight: '65vh' }}>
        <div className="pb-4">
          <h1 className="font-display text-2xl tracking-tight text-gray-900 sm:text-3xl">Update Profile</h1>
        </div>
        <form onSubmit={handleUpdateProfile}>
          <div className="mt-6 grid grid-cols-1 gap-6">
            <div>
              <label
                htmlFor="displayName"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Display Name:
              </label>
              <input
                type="text"
                id="displayName"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                placeholder="Enter your display name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="newEmail"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Email Address:
              </label>
              <input
                type="email"
                id="newEmail"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                placeholder={`${user.email}`}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </div>
            <div>
              <hr className="my-3 border-gray-300" />
              <div className="pb-6 pt-4">
                <h2 className="font-display text-xl tracking-tight text-gray-900 sm:text-1xl">Update Password</h2>
              </div>
              {/* <div className="flex items-center mb-3 text-red-500">
                <span className="mr-1">*</span>
                <span className="text-sm">Update Password</span>
              </div> */}
              <label
                htmlFor="currentPassword"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Current Password:
              </label>
              <input
                type="password"
                id="currentPassword"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                placeholder="Enter your current password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="newPassword"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                New Password:
              </label>
              <input
                type="password"
                id="newPassword"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                placeholder="Enter your new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="confirmPassword"
                className="mb-3 block text-sm font-medium text-gray-700"
              >
                Confirm Password:
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                placeholder="Confirm your new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-10 mb-10 flex items-end justify-center">
            <Button onClick={ handleUpdateProfile }>
              Save Profile
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
