import { parse } from 'papaparse';
import React, { useState, useContext, useEffect } from 'react';
import { Container } from '../../components/Container.js';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';
import { Button } from '../../components/Button.js';
import { doc, getDocs, getDoc, collection, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../firebase.js';
import { ApiContext } from '../../contexts/ApiContext.js';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

const CreateAffiliate = () => {
    const [newAffiliateName, setNewAffiliateName] = useState("");
    const [newAffiliateEmail, setNewAffiliateEmail] = useState("");
    const [selectedUploadDeepLinkOption, setSelectedUploadDeepLinkOption] = useState('single');
    const [csvFile, setCsvFile] = useState(null);
    const [startNumber, setStartNumber] = useState(1);
    const [selectedDeepLink, setSelectedDeepLink] = useState(null);
    const user = FetchUserAndFirebaseDocsOnAuth();
    const [passwordResetLink, setPasswordResetLink] = useState(null);
    const {
        subscriptionStatus,
        companyUnassignedDeepLinks,
        setCompanyUnassignedDeepLinks } = useContext(ApiContext);

    const [ localCompanyUnassignedDeepLinks, setLocalCompanyUnassignedDeepLinks ] = useState(companyUnassignedDeepLinks);

    const navigate = useNavigate();

    useEffect(() => {
        if (companyUnassignedDeepLinks !== '' && companyUnassignedDeepLinks !== localCompanyUnassignedDeepLinks) {
            setLocalCompanyUnassignedDeepLinks(companyUnassignedDeepLinks.map(code => ({
                    value: code,
                    label: `${code}`
                }))
            );
        }
      }, [companyUnassignedDeepLinks]);
    
    const handleChange = (newValue, actionMeta) => {
        setSelectedDeepLink(newValue);
    };

    const handleCreate = (inputValue) => {
        const newOption = {
            value: inputValue,
            label: `${inputValue}`,
        };

        setLocalCompanyUnassignedDeepLinks((prevOptions) => [...prevOptions, newOption]);
        setSelectedDeepLink(newOption);
    };
    

    useEffect(() => {
        if (subscriptionStatus === false || subscriptionStatus === undefined) {
            navigate('/billing');
        }
    }, [subscriptionStatus, navigate]);

    const auth = getAuth();

    // Utility function to read the CSV file
    const readCsvFile = (csvFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsText(csvFile);
        });
    };

    // Function to find short URLs by marketing title
    const findShortUrlsByTitle = async (csvFile, pattern) => {
        try {
            const csvData = await readCsvFile(csvFile);
            const results = parse(csvData, { header: false });
            const data = results.data;
    
            // Identify the correct headers (assumed to be in the 6th row)
            const headersRowIndex = 6;
            const headers = data[headersRowIndex];
    
            // Convert array of headers to object keys
            const headerKeys = {
                marketingTitle: headers[2],
                shortUrl: headers[3],
            };
    
            // Extract relevant rows (after the header row)
            const relevantData = data.slice(headersRowIndex + 1);
    
            // Filter the rows based on the pattern and map to short URLs
            const matchingUrls = relevantData
                .filter(row => row[2] && row[2].includes(pattern))
                .map(row => row[3]);
    
            return matchingUrls;
        } catch (error) {
            console.error("Error processing CSV file: ", error);
            return [];
        }
    };

    const removeUrlFromUnassignedDeepLinks = async (urlToRemove) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        try {
            // Fetch the current company unassigned deep links
            const userDocSnapshot = await getDoc(userDocRef);
            const currentUnassignedDeepLinks = userDocSnapshot.data().companyUnassignedDeepLinks || [];
    
            // Filter out the URL to be removed
            const updatedUnassignedDeepLinks = currentUnassignedDeepLinks.filter(url => url !== urlToRemove);
    
            // Update Firestore with the new array
            await updateDoc(userDocRef, {
                companyUnassignedDeepLinks: updatedUnassignedDeepLinks,
            });

        } catch (error) {
            console.error('Error removing URL from companyUnassignedDeepLinks:', error);
        }
    };    

    const handleCsvUpload = async (csvFile) => {
        if (!csvFile) {
            console.error("No CSV file provided");
            return;
        }

        const pattern = "Insert Affiliate Link"; // Replace with the actual pattern you want to match

        const matchingUrls = await findShortUrlsByTitle(csvFile, pattern);
        matchingUrls.push("https://wvcxo.app.link/vesspSGH0Ib")


        // TODO: I only want to upload this if it's nto already within the companyUnassignedDeepLinks for this user in firebase.
        // I also need to check if the deep link is already in use by another affiliate
        // If it is, remove it befor ecalling updateAndSaveDeepLinks

        const uniqueUrls = await filterUniqueAndUnassignedUrls(matchingUrls);

        if (uniqueUrls.length > 0) {
            updateAndSaveDeepLinks({ newCompanyUnassignedDeepLinks: uniqueUrls });
        }
    };

    const filterUniqueAndUnassignedUrls = async (urls) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
    
        // Fetch the current company unassigned deep links
        const userDocSnapshot = await getDoc(userDocRef);
        const currentUnassignedDeepLinks = userDocSnapshot.data().companyUnassignedDeepLinks || [];
    
        const affiliateCollectionRef = collection(userDocRef, 'Affiliates');
        const affiliateSnapshot = await getDocs(affiliateCollectionRef);
    
        // Get all deep links currently used by affiliates
        const affiliateDeepLinks = [];
        affiliateSnapshot.forEach(doc => {
            const affiliateData = doc.data();
            if (affiliateData.deeplinkurl) {
                affiliateDeepLinks.push(affiliateData.deeplinkurl);
            }
        });
    
        // Filter out URLs already in unassigned deep links or used by affiliates
        const uniqueUrls = urls.filter(url => 
            !currentUnassignedDeepLinks.includes(url) && !affiliateDeepLinks.includes(url)
        );
    
        return uniqueUrls;
    };
    

    const updateAndSaveDeepLinks = async ({ newCompanyUnassignedDeepLinks }) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
        const affiliateCollectionRef = collection(userDocRef, 'Affiliates');

        try {
            await updateDoc(userDocRef, {
                companyUnassignedDeepLinks: newCompanyUnassignedDeepLinks,
            });

            setCompanyUnassignedDeepLinks(newCompanyUnassignedDeepLinks);
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    const handleDownloadTemplate = async () => {
        if (!user.uid) {
            console.error("No company name provided");
            return;
        }

        try {
            // Await the response from fetchFirestoreAffiliatesCount
            let startNumber = await fetchFirestoreAffiliatesCount(user.uid);

            setStartNumber(startNumber);
    
            // Ensure startNumber is at least 1
            startNumber = Math.max(startNumber, 1);
    
            // Define the CSV content based on the user's data
            const numberOfLines = 100;
            let csvContent = "campaign,data\n";
    
            for (let i = startNumber; i < startNumber + numberOfLines; i++) {
                csvContent += `insert-affiliate,"{ ""$marketing_title"":""Insert Affiliate Link ${i}"", ""$og_description"":"""" }"\n`;
            }
    
            // Create a Blob from the CSV content
            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "template.csv");
    
            // Append the link to the document body and trigger the download
            document.body.appendChild(link);
            link.click();
    
            // Clean up and remove the link
            document.body.removeChild(link);
            URL.revokeObjectURL(url); // Release the URL object
        } catch (error) {
            console.error("Error handling download template: ", error);
        }
    };

    const fetchFirestoreAffiliatesCount = async (uid) => {
        if (!uid) {
            console.error("User UID is not provided");
            return;
        }

        try {
            const companiesRef = collection(db, 'Companies');
            const userDocRef = doc(companiesRef, uid);
            const affiliateCollectionRef = collection(userDocRef, 'Affiliates');

            const affiliateSnapshot = await getDocs(affiliateCollectionRef);
            const affiliatesCount = affiliateSnapshot.size; // This gives the count of documents

            return affiliatesCount + 1;
        } catch (error) {
            console.error("Error fetching affiliates count: ", error);
        }
    };


    const createFirebaseAffiliate = async () => {
        // TODO: Michael continue here. This user IS an affiliate - but you just have to check for it on login not on create as you're still logged in as your old user otherwise
        if (!user.uid) {
            console.error("No company name provided");
            return;
        }

        removeUrlFromUnassignedDeepLinks(selectedDeepLink.value);

        // Prepare the affiliate data
        const affiliateData = {
            affiliatename: newAffiliateName,
            email: newAffiliateEmail,
            deeplinkurl: selectedDeepLink.value,
            affiliateOfferCode: "",
            createdAt: new Date().toISOString()
        };

        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(db, 'Companies', user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), newAffiliateEmail);

        try {
            await setDoc(affiliateRef, affiliateData, { merge: true });

            const url = `${process.env.REACT_APP_API_URL}/createAffiliateUser`;
            const payload = {
                email: newAffiliateEmail,
                displayName: newAffiliateName,
                companyId: user.uid
            };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
        
                const data = await response.json();
                if (!response.ok) {
                    throw new Error(data.error || 'Unknown error occurred');
                }

                setPasswordResetLink(data.link); 
            } catch (error) {
                console.error('Error creating affiliate:', error.message);
            }
        } catch (error) {
            console.error('Error creating affiliate:', error);
            alert('Failed to create affiliate');
        }
    };

    return (
        <div>
            <Container>
                <div>
                    <div className="pb-8 mx-auto"  style={{ minHeight: '65vh' }}>
                        {passwordResetLink ? (
                            <div className="p-2.5 rounded-lg text-center">
                                <div className="pb-4">
                                    <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                        Affiliate successfully created
                                    </h1>
                                    <p className="mt-2 mb-4 text-lg tracking-tight text-slate-700">
                                        Send the below link to your new affiliate for them to set their password
                                    </p>
                                </div>
                                <div className="p-3 bg-gold-900 rounded-lg overflow-hidden">
                                    <a href={passwordResetLink} 
                                        target="_blank" 
                                        rel="noopener noreferrer" 
                                        className="overflow-hiddeninline-block text-black p-2 rounded-md text-sm leading-tight break-words"
                                    >
                                    {passwordResetLink}
                                    </a>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="pb-4">
                                    <h1 className="mb-2 font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                                        Create An Affiliate
                                    </h1>
                                    {/* <p className="mt-2 mb-2 text-lg tracking-tight text-slate-700">
                                        Ready to create a new affiliate and track their sales?
                                    </p> */}
                                    <div className="flex flex-col mb-4">
                                        <label className="mb-2">
                                            <input 
                                                type="radio" 
                                                value="single"
                                                className="mr-2"
                                                checked={selectedUploadDeepLinkOption === 'single'} 
                                                onChange={() => setSelectedUploadDeepLinkOption('single')} 
                                            />
                                            Create Affiliate (Admin)
                                        </label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                value="multiple"
                                                className="mr-2"
                                                checked={selectedUploadDeepLinkOption === 'multiple'} 
                                                onChange={() => setSelectedUploadDeepLinkOption('multiple')} 
                                            />
                                            Enable Affiliate Self-Signup
                                        </label>
                                    </div>
                                </div>
                               
                                <form onSubmit={(e) => { e.preventDefault() }} >

                                    <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-6">
                                        {selectedUploadDeepLinkOption === 'single' ? (
                                            <div>
                                                <h3 className="mb-2 font-display text-xl tracking-tight text-slate-900 sm:text-1xl">
                                                    Affiliate Details
                                                </h3>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliateName"
                                                        className="mb-3 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Name:
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="newAffiliateName"
                                                        value={newAffiliateName}
                                                        onChange={(e) => setNewAffiliateName(e.target.value)}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div>
                                                    <label
                                                        htmlFor="newAffiliateEmail"
                                                        className="mt-4 mb-2 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Email:
                                                    </label>
                                                    <input
                                                        type="email"
                                                        id="newAffiliateEmail"
                                                        value={newAffiliateEmail}
                                                        onChange={(e) => setNewAffiliateEmail(e.target.value)}
                                                        className="block w-full appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm"
                                                    />
                                                </div>
                                                <div className="relative">
                                                    <label
                                                        htmlFor="selectedDeepLink"
                                                        className="mt-4 mb-2 block text-sm font-medium text-gray-700"
                                                    >
                                                        Affiliate Deep Link:
                                                    </label>
                                                    <div className="flex items-center">
                                                        <CreatableSelect
                                                            id="localCompanyUnassignedDeepLinks"
                                                            isClearable
                                                            value={selectedDeepLink}
                                                            onChange={handleChange}
                                                            onCreateOption={handleCreate}
                                                            options={localCompanyUnassignedDeepLinks}
                                                            placeholder="Select existing or add a new deep link"
                                                            classNamePrefix="react-select"
                                                        />
                                                        <Button
                                                            className="ml-4"
                                                            aria-label="Help"
                                                            onClick={() => window.open('https://docs.insertaffiliate.com/branch-create-affiliate', '_blank')}
                                                        >
                                                            Help
                                                        </Button>
                                                    </div>
                                                </div>
                                                <Button onClick={ createFirebaseAffiliate } className="mt-4">Create Affiliate</Button>
                                            </div>
                                        ) : (
                                        <div>
                                            <div className=" mb-4 flex flex-col items-start">
                                                <h3 className="mb-2 font-display text-xl tracking-tight text-slate-900 sm:text-1xl">
                                                    Create Multiple Deep Links
                                                </h3>
                                                <p className="mb-4">
                                                    Creating deep links ready to use allows affiliates to sign up and join your affiliate program on their own, provided they know the company code.
                                                </p>
                                                <span>
                                                    1. Download your personal Deep Link CSV template below
                                                </span>
                                                <div
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="mt-2 cursor-pointer inline-block px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default behavior
                                                        handleDownloadTemplate(); 
                                                    }}
                                                >
                                                    Download CSV Template
                                                </div>
                                                <span className="mt-6">
                                                    2. Upload the CSV file to create multiple deep links for future users as shown in our 
                                                    <a
                                                        href="https://docs.insertaffiliate.com/branch-self-signup-create-affiliate"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="ml-1 text-purple-600 hover:underline"
                                                    >
                                                        user guide
                                                    </a>
                                                </span>

                                                <span className="mt-6">
                                                3. Export the created affiliates
                                                    <a
                                                        href="https://docs.insertaffiliate.com/branch-self-signup-create-affiliate"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="ml-1 text-purple-600 hover:underline"
                                                    >
                                                        user guide
                                                    </a>
                                                </span>
                                                <span className="mt-6">
                                                    4. Upload the exported CSV file to create multiple deep links
                                                </span>
                                              
                                                <div className="flex items-center mt-4 ">
                                                    <input
                                                        type="file"
                                                        id="csvUpload"
                                                        accept=".csv"
                                                        onChange={(e) => {
                                                            const file = e.target.files[0];
                                                            if (file && file.type === "text/csv") {
                                                                handleCsvUpload(file);
                                                            } else {
                                                                alert("Please upload a valid CSV file.");
                                                            }
                                                        }}
                                                        className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                                                    />
                                                    <a
                                                        href="https://docs.insertaffiliate.com/branch-bulk-create-affiliate"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="ml-4 text-sm text-blue-600 hover:underline"
                                                    >
                                                        Help
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        )}
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default CreateAffiliate;