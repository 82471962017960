import React, { useState, useEffect } from 'react';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AffiliateSalesTransactions = ({
        tableData,
        startDate,
        endDate,
        setStartDate,
        setEndDate,
        companyAffiliatePaymentPercentage,
        transactionsAffiliateHasBeenPaidFor,
        setTransactionsAffiliateHasBeenPaidFor,
        transactionsAffiliateHasNotBeenPaidFor,
        setTransactionsAffiliateHasNotBeenPaidFor,
        affiliateEmail,
        user,
        totalAffiliateHasBeenPaid,
        setTotalAffiliateHasBeenPaid,
        totalAffiliateHasNotBeenPaid,
        setTotalAffiliateHasNotBeenPaid,
    }) => {
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [initalised, setInitialised] = useState(false);
    const [filter, setFilter] = useState('unpaid'); // 'all', 'paid', 'unpaid'
    

    useEffect(() => {
        if (transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasNotBeenPaidFor && totalAffiliateHasNotBeenPaid && totalAffiliateHasBeenPaid && initalised) {
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                transactionsAffiliateHasBeenPaidFor,
                transactionsAffiliateHasNotBeenPaidFor,
                totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid
            });
        } else {
            setInitialised(true);
        }
    }, [transactionsAffiliateHasBeenPaidFor, transactionsAffiliateHasNotBeenPaidFor, totalAffiliateHasBeenPaid, totalAffiliateHasNotBeenPaid]);    

    const updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                transactionsAffiliateHasBeenPaidFor: affiliate.transactionsAffiliateHasBeenPaidFor,
                transactionsAffiliateHasNotBeenPaidFor: affiliate.transactionsAffiliateHasNotBeenPaidFor,
                totalAffiliateHasBeenPaid: affiliate.totalAffiliateHasBeenPaid,
                totalAffiliateHasNotBeenPaid: affiliate.totalAffiliateHasNotBeenPaid
            });
        } catch (error) {
            // console.error("Error updating affiliate:", error);
        }
    }

    const handleSelectItem = (transactionId) => {
        setSelectedItems(prevState => {
            const newState = new Set(prevState);
            if (newState.has(transactionId)) {
                newState.delete(transactionId);
            } else {
                newState.add(transactionId);
            }
            return newState;
        });
    };

    const handleSelectAll = () => {
        const allItems = new Set(tableData.flatMap(row => Object.values(row.transactions)).map(purchase => purchase.transactionId));
        if (selectedItems.size === allItems.size) {
            setSelectedItems(new Set());
        } else {
            setSelectedItems(allItems);
        }
    };

    const markAllAsPaid = () => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => {
            const newPaidTransactions = [...prevState];
            selectedItems.forEach(transactionId => {
                if (!newPaidTransactions.includes(transactionId)) {
                    newPaidTransactions.push(transactionId);
                }
            });
            return newPaidTransactions;
        });

        setTransactionsAffiliateHasNotBeenPaidFor(prevState => {
            const newUnpaidTransactions = prevState.filter(transactionId => !selectedItems.has(transactionId));
            
            const selectedTransactions = tableData.flatMap(row => Object.values(row.transactions)).filter(purchase => selectedItems.has(purchase.transactionId));
            const amountToAdd = selectedTransactions.reduce((total, purchase) => total + (purchase.amountOwedToAffiliate || 0), 0);
            
            setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amountToAdd);
            setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amountToAdd);
    
            updateFirebaseAffiliateTransactionsAffiliateHasBeenPaidFor({
                affiliateEmail,
                transactionsAffiliateHasBeenPaidFor: [...transactionsAffiliateHasBeenPaidFor, ...Array.from(selectedItems)],
                transactionsAffiliateHasNotBeenPaidFor: newUnpaidTransactions,
                totalAffiliateHasBeenPaid: totalAffiliateHasBeenPaid + amountToAdd,
                totalAffiliateHasNotBeenPaid: totalAffiliateHasNotBeenPaid - amountToAdd
            });
    
            return newUnpaidTransactions;
        });
    };
    
    const markTransactionAsPaid = (transactionId) => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => [...(prevState || []), transactionId]);
        setTransactionsAffiliateHasNotBeenPaidFor(prevState => prevState.filter(id => id !== transactionId));

        const transaction = tableData.flatMap(row => Object.values(row.transactions)).find(purchase => purchase.transactionId === transactionId);
        const amount = transaction?.amountOwedToAffiliate || 0;

        setTotalAffiliateHasBeenPaid(prevTotal => prevTotal + amount);
        setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal - amount);
    }

    const markTransactionAsUnpaid = (transactionId) => {
        setTransactionsAffiliateHasBeenPaidFor(prevState => prevState.filter(id => id !== transactionId));
        setTransactionsAffiliateHasNotBeenPaidFor(prevState => [...(prevState || []), transactionId]);

        const transaction = tableData.flatMap(row => Object.values(row.transactions)).find(purchase => purchase.transactionId === transactionId);
        const amount = transaction?.amountOwedToAffiliate || 0;
    
        setTotalAffiliateHasBeenPaid(prevTotal => prevTotal - amount);
        setTotalAffiliateHasNotBeenPaid(prevTotal => prevTotal + amount);
    };

    const filteredTableData = tableData.flatMap((row, rowIndex) => 
        Object.values(row.transactions).filter(purchase => {
            if (filter === 'all') return true;
            if (filter === 'paid') return transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId);
            if (filter === 'unpaid') return !transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId);
        }).map((purchase, purchaseIndex) => (
            <tr key={`${rowIndex}-${purchaseIndex}`}>
                <td className="text-center">
                    <input
                        type="checkbox"
                        checked={selectedItems.has(purchase.transactionId)}
                        onChange={() => handleSelectItem(purchase.transactionId)}
                    />
                </td>
                <td>{getAffiliateNameFromUrlString(row.applicationUsername)}</td>
                <td>{purchase.transactionId}</td>
                <td>{purchase.purchaseId}</td>
                <td>{purchase.platform}</td>
                <td>{purchase.sandbox ? "Yes" : "No"}</td>
                <td>{purchase.productId}</td>
                <td>{formatDateOutput(purchase.purchaseDate)}</td>
                <td>{formatDateOutput(purchase.lastRenewalDate)}</td>
                <td>{purchase.expirationDate ? formatDateOutput(purchase.expirationDate) : "N/A"}</td>
                <td>{purchase.renewalIntent ? purchase.renewalIntent : "N/A"}</td>
                <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD : "Not Available - Perhaps it's in sandbox"}</td>
                <td>{(purchase.amountUSD !== null && purchase.amountUSD !== undefined) ? purchase.amountUSD * (companyAffiliatePaymentPercentage / 100) : "Not Available - Perhaps it's in sandbox"}</td>
                <td className={` ${transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? 'text-green-600' : 'text-red-600'}`}>
                    {(transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId)) ? "Paid" : "Unpaid"}
                </td>
                <td className="whitespace-nowrap">
                    {transactionsAffiliateHasBeenPaidFor && transactionsAffiliateHasBeenPaidFor.includes(purchase.transactionId) ? (
                        <button onClick={() => markTransactionAsUnpaid(purchase.transactionId)} className="text-red-600">
                            Mark as Unpaid
                        </button>
                    ) : (
                        <button onClick={() => markTransactionAsPaid(purchase.transactionId)} className="text-purple-600">
                            Mark as Paid
                        </button>
                    )}
                </td>
            </tr>
        ))
    );
    

    return (
        <div>
            <h2 className="mt-12 mb-4 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">
                Affiliate's Sales Transactions 
            </h2>
            <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                <div className="">
                    <select
                        id="filter"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        className="px-10 py-2 border rounded-md"
                    >
                        <option value="all">All</option>
                        <option value="paid">Paid</option>
                        <option value="unpaid">Unpaid</option>
                    </select>
                </div>
                <div className="flex-grow">
                    <label htmlFor="startDate" className="hidden">
                        Start Date:
                    </label>
                    <DateInputWithPlaceholder
                        id="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                    />
                </div>
                <div className="flex-grow">
                    <label htmlFor="endDate" className="hidden">
                        End Date:
                    </label>
                    <DateInputWithPlaceholder
                        id="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="End Date"
                    />
                </div>
            </div>
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="text-center">
                            <input
                                type="checkbox"
                                checked={selectedItems.size === tableData.flatMap(row => Object.values(row.transactions)).length}
                                onChange={handleSelectAll}
                            />
                        </th>
                        <th>Customer UUID</th>
                        <th>Transaction ID</th>
                        <th>Purchase ID</th>
                        <th>Platform</th>
                        <th>Sandbox</th>
                        <th>Product Id</th>
                        <th>Original Purchase Date</th>
                        <th>Last Renewal Date</th>
                        <th>Expiration Date</th>
                        <th>Renewal Intent</th>
                        <th>Total Earnings ($)</th>
                        <th>Amount Owed to Affiliate</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {filteredTableData}

                    <tr className="totalRow">
                    
                        <td colSpan="15">
                            <button onClick={markAllAsPaid} className="text-purple-600">
                                Mark All Paid
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr className="totalRow">
                    <td colSpan="2">Total</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                            {/* Should be total for selected, not for all. */}
                            {tableData.flatMap(row => Object.values(row.transactions))
                                .filter(purchase => purchase.amountUSD !== null && purchase.amountUSD !== undefined)
                                .reduce((total, purchase) => total + purchase.amountUSD, 0)
                                .toFixed(2)}
                        </td>
                        <td>
                            {tableData.flatMap(row => Object.values(row.transactions))
                                .filter(purchase => purchase.amountUSD !== null && purchase.amountUSD !== undefined)
                                .reduce((total, purchase) => total + purchase.amountUSD, 0)
                                .toFixed(2)

                                * (companyAffiliatePaymentPercentage / 100)}
                        </td>
                        <td></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

const DateInputWithPlaceholder = ({ id, value, onChange, placeholder }) => {
    const [isFocused, setIsFocused] = useState(false);
  
    const handleFocus = (e) => {
      setIsFocused(true);
      e.target.type = 'date';
      e.target.placeholder = '';
    };
  
    const handleBlur = (e) => {
      if (!e.target.value) {
        e.target.type = 'text';
        e.target.placeholder = placeholder;
      }
      setIsFocused(false);
    };
  
    return (
      <div className="relative">
        <input
          type="text"
          id={id}
          value={value}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={isFocused ? '' : placeholder}
          className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
          style={{ width: '100%', minWidth: '180px' }}
        />
      </div>
    );
};

const formatDateOutput = (date) => {
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).format(new Date(date));
};

const getAffiliateNameFromUrlString = (url) => {
    if (url.includes('/')) {
        const parts = url.split('/');
        return parts[parts.length - 1];
    }
    return url;
};

export default AffiliateSalesTransactions;
