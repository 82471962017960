import React, { useState, useEffect, useContext } from 'react';
import { Container } from '../../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';

import { db } from '../../firebase';
import { collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';

import AffiliateDetails from './AffiliateDetails';
import AffiliateSalesTransactions from './AffiliateSalesTransactions';

const AffiliateSearch = () => { // tableData, setTableData,
    const [tableData, setTableData] = useState([]);
    const [affiliateCode, setAffiliateCode] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState(null);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const {
        iapticAppName,
        apiUrl,
        subscriptionStatus,
        companyOfferCouponCodes,
        companyAffiliatePaymentPercentage
    } = useContext(ApiContext);

    const [localCompanyOfferCouponCodes, setLocalCompanyOfferCouponCodes] = useState(
        (companyOfferCouponCodes || []).map(code => ({
            value: code.value,
            label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
            expiry: code.expiry
        }))
    );

    const {iapticSecretKey} = useContext(ApiContext)
    const user = FetchUserAndFirebaseDocsOnAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [affiliateEmail, setAffiliateEmail] = useState('');
    const [affiliateName, setAffiliateName] = useState('');
    const [deepLinkUrl, setDeepLinkUrl] = useState('');
    const [affiliateOfferCode, setAffiliateOfferCode] = useState('');
    const [localAffiliateOfferCode, setLocalAffiliateOfferCode] = useState('');
    const [transactionsAffiliateHasBeenPaidFor, setTransactionsAffiliateHasBeenPaidFor] = useState([]);
    const [transactionsAffiliateHasNotBeenPaidFor, setTransactionsAffiliateHasNotBeenPaidFor] = useState([]);

    const [totalAffiliateHasBeenPaid, setTotalAffiliateHasBeenPaid] = useState(0);
    const [totalAffiliateHasNotBeenPaid, setTotalAffiliateHasNotBeenPaid] = useState(0);

    const [selectedCode, setSelectedCode] = useState('');

    const handleChange = (newValue, actionMeta) => {
        setSelectedCode(newValue);
        setLocalAffiliateOfferCode(newValue);
    };

    const handleCreate = (inputValue) => {
        const expiryDate = prompt('Enter expiry date (YYYY-MM-DD):');
        const newOption = {
            value: inputValue,
            label: `${inputValue} (expires: ${new Date(expiryDate).toLocaleDateString()})`,
            expiry: expiryDate
        };
        setLocalCompanyOfferCouponCodes((prevOptions) => [...prevOptions, newOption]);
        setSelectedCode(newOption);
    };


    useEffect(() => {
        if (companyOfferCouponCodes !== '' && companyOfferCouponCodes !== localCompanyOfferCouponCodes) {
          setLocalCompanyOfferCouponCodes(
            companyOfferCouponCodes.map(code => ({
                value: code.value,
                label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
                expiry: code.expiry
            }))
          );
        }
    }, [companyOfferCouponCodes]);

    useEffect(() => {
        if (affiliateOfferCode !== '' && affiliateOfferCode != null && affiliateOfferCode !== localAffiliateOfferCode) {
            const selectedOption = localCompanyOfferCouponCodes.find(option => option.value === affiliateOfferCode);
            setLocalAffiliateOfferCode(affiliateOfferCode);
            setSelectedCode(selectedOption || { value: affiliateOfferCode, label: affiliateOfferCode });
        }
    }, [affiliateOfferCode]);

    useEffect(() => {
        if (subscriptionStatus === false || subscriptionStatus === undefined) {
            navigate('/billing');
        }
    }, [subscriptionStatus, navigate]);

    useEffect(() => {
        if (user !== null && iapticAppName !== '' && iapticSecretKey !== '') {
            // Check for affiliate parameter in URL
            const params = new URLSearchParams(location.search);
            const affiliateParam = params.get('affiliate');
            if (affiliateParam) {
                setAffiliateCode(affiliateParam);
            }
        }
    }, [user, iapticAppName, iapticSecretKey]);

    useEffect(() => {
        if (user !== null && iapticAppName !== '' && iapticSecretKey !== '' && affiliateCode !== '') {
            // Trigger search
            searchAnAffiliate();
        }
    }, [user, iapticAppName, iapticSecretKey, affiliateCode, startDate, endDate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFirebaseAffiliateOfferCode({
            affiliateEmail,
            localAffiliateOfferCode
        });
        const offerCouponCodesAsStrings = localCompanyOfferCouponCodes.map(option => ({
          value: option.value,
          expiry: option.expiry
        }));
        updateAndSaveSettings({
          companyOfferCouponCodes: offerCouponCodesAsStrings
        });
    };

    const updateAndSaveSettings = async ({ companyOfferCouponCodes }) => {
        // Assuming your Firestore collection is named 'copanies'
        const companiesRef = collection(db, 'Companies');
    
        const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID
    
        const privateRef = collection(userDocRef, 'Private');
        const privateDocRef = doc(privateRef, 'Credentials');
        
        try {
            const userDocSnapshot = await getDoc(userDocRef);
    
            // Update the Firestore document with the new settings
            await updateDoc(userDocRef, {
              companyOfferCouponCodes: companyOfferCouponCodes
            });
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };

    const updateFirebaseAffiliateOfferCode = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                affiliateOfferCode: affiliate.localAffiliateOfferCode.value
            });

        } catch (error) {
            console.error("Error updating affiliate:", error);
        }
    }

    const findAffiliateDeepLinkCodeInFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);

        if (foundAffiliate) {
            return foundAffiliate.deeplinkurl;
        } else {
            return null;
        }
    };

    const findAffiliateTransactionsAffiliateHasBeenPaidForInFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);

        if (foundAffiliate) {
            return foundAffiliate.transactionsAffiliateHasBeenPaidFor;
        } else {
            return null;
        }
    };

    const setAffiliateDetailsRetrievedFromFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);

        if (foundAffiliate) {
            setAffiliateEmail(foundAffiliate.email);
            setAffiliateName(foundAffiliate.affiliatename);
            setDeepLinkUrl(foundAffiliate.deeplinkurl);
            setAffiliateOfferCode(foundAffiliate.affiliateOfferCode);
            setTransactionsAffiliateHasBeenPaidFor(foundAffiliate.transactionsAffiliateHasBeenPaidFor || []);
            setTransactionsAffiliateHasNotBeenPaidFor(foundAffiliate.transactionsAffiliateHasNotBeenPaidFor || []);
            setTotalAffiliateHasBeenPaid(foundAffiliate.totalAffiliateHasBeenPaid || 0);
            setTotalAffiliateHasNotBeenPaid(foundAffiliate.totalAffiliateHasNotBeenPaid || 0);
        }
    };

    const fetchFirestoreAffiliates = async (uid) => {
        if (!uid) {
          console.error("User UID is not provided");
          return [];
        }

        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, uid);
    
        const affiliateCollection = collection(userDocRef, 'Affiliates');
    
        // how to loop over each in affiliateCollection
        const affiliateCollectionSnapshot = await getDocs(affiliateCollection);
    
        let groupedDataFirebase = [];
        
        // how to loop over affiliateCollectionSnapshot and output all the data
        affiliateCollectionSnapshot.forEach((doc) => {
            let affiliateEntry = {};
            affiliateEntry.email = doc.data().email
            affiliateEntry.affiliatename = doc.data().affiliatename
            affiliateEntry.deeplinkurl = doc.data().deeplinkurl
            affiliateEntry.affiliateOfferCode = doc.data().affiliateOfferCode
            affiliateEntry.transactionsAffiliateHasBeenPaidFor = doc.data().transactionsAffiliateHasBeenPaidFor
            groupedDataFirebase.push(affiliateEntry);
          
        });
    
        return groupedDataFirebase
    };

    const formatDate = (date) => {
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }

    const refactorIapticCustomersToMatchFirebaseAffiliates = (iapticCustomers, firebaseAffiliates) => {
        // Check if iapticCustomers is an array
        if (!Array.isArray(iapticCustomers)) {
            // console.error("iapticCustomers is not a valid array, possibly null or undefined.");
            return [];
        }
    
        return iapticCustomers.map((iapticCus) => {
            let affiliateMatched = false;
    
            firebaseAffiliates.forEach((firebaseAffiliate) => {
                if (firebaseAffiliate.deeplinkurl === iapticCus.applicationUsername) {
                    iapticCus.applicationUsername = firebaseAffiliate.affiliatename;
                    affiliateMatched = true;
                }
            });
    
            return affiliateMatched ? iapticCus : null;
        }).filter(Boolean);
    };

    const searchAnAffiliate = () => {
        setSearchExecuted(true);
        setTableData([]);
        setError(null); // Clear previous errors


        if (affiliateCode === '') {
            setError("Affiliate code cannot be empty. Please enter a valid affiliate code.");

            return;
        } else if ((startDate === '' || !startDate) && endDate === '') {
            fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Error fetching customers: ${response.status}`);
                    }
                    return response.json();
                })
                .then(async customers => {
                    if (!customers) {
                        throw new Error("Customers data is undefined. Please check your settings.");
                    }

                    // Find the affiliate within Firebase...
                    try {
                        const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

                        let affiliateDeepLinkCode = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                        // let transactionsAffiliateHasBeenPaidFor = findAffiliateTransactionsAffiliateHasBeenPaidForInFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                        setAffiliateDetailsRetrievedFromFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                        // Find the affiliate within the list of customers from the receipt verification company
                        const filteredCustomers = customers.filter(customer => customer.startsWith(affiliateDeepLinkCode));

                        // Map each customer to a promise that fetches their purchases
                        const promises = filteredCustomers.map(customer => {
                            const encodedCustomer = encodeURIComponent(customer);
                            
                            // The customer may be a url, like https://wvcxo.app.link/agwijEfw1Ib which needs escaping, how?
                            return fetch(`${apiUrl}/returnCustomerPurchases/${encodedCustomer}?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                                .then(response => response.json());
                        });

                        // Wait for all promises to resolve
                        return Promise.all(promises);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }
                })
                .then(async purchases => {
                    // 'purchases' is an array of arrays containing purchase data for each customer
                    
                    try {
                        const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);
                        refactorIapticCustomersToMatchFirebaseAffiliates(purchases, firebaseAffiliates);
                        setTableData(purchases.flat());
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }

                    // TODO: I need to look at the affiliatename and convert this against firebase (its currently the firebase deeplinkurl sometimes)
                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch customers data. Tip: This often happens if your Iaptic credentials are incorrect.");
                });
        } else {
            fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                .then(response => response.json())
                .then(async customers => {
                    // Find the affiliate within Firebase...
                    try {
                        const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

                        let affiliateDeepLinkCode = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                        setAffiliateDetailsRetrievedFromFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                        if (!affiliateDeepLinkCode) {
                            throw new Error("Affiliate deep link code not found in Firebase. Please check the affiliate code.");
                        }

                        // Find the affiliate within the list of customers from the receipt verification company
                        const filteredCustomers = customers.filter(customer => customer.startsWith(affiliateDeepLinkCode));

                        if (filteredCustomers.length === 0) {
                            setError("No matching customers found for the provided affiliate code.");
                            return;
                        }

                        let defaultStartDate = startDate || '2020-01-01'; // Use '01/01/2020' if startDate is null
                        let tomorrow = new Date();
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        let defaultEndDate = endDate || formatDate(tomorrow); // Use tomorrow's date if endDate is null

                        const promises = filteredCustomers.map(customer => {
                            const encodedCustomer = encodeURIComponent(customer);
    
                            return fetch(`${apiUrl}/returnCustomerPurchasesBetweenDates/${encodedCustomer}/${defaultStartDate}/${defaultEndDate}/${iapticAppName}/${iapticSecretKey}`)
                                .then(response => {
                                    if (!response.ok) {
                                        throw new Error(`Error fetching purchases for customer ${encodedCustomer}: ${response.status}`);
                                    }
                                    return response.json();
                                })
                                .catch(error => {
                                    console.error(`Error fetching data for ${customer}:`, error);
                                    return null; // Handle error and continue processing other requests
                                });
                        });

                        // Wait for all promises to resolve
                        return Promise.all(promises);
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }

                })
                .then(async purchases => {
                    try {
                        const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

                        refactorIapticCustomersToMatchFirebaseAffiliates(purchases, firebaseAffiliates);

                        // You may want to process the 'purchases' array to get the desired format for 'setTableData'
                        // For example, you might flatten the array if each customer's purchases are in a separate array
                        setTableData(purchases.flat());
                    } catch (error) {
                        console.error("Error fetching data:", error);
                    }

                })
                .catch(error => {
                    console.error("Error fetching data:", error);
                    setError("Failed to fetch customers data. Tip: This often happens if your Iaptic credentials are incorrect.");
                });
        }
    };

    return (
        <Container>
            <div>
                <div className="pb-8 mx-aut" style={{ minHeight: '65vh' }}>
                    <div className="pb-2">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Search An Affiliate
                        </h1>
                        <p className="mt-2 text-lg tracking-tight text-slate-700">
                            Search for an affiliate by their identifier to find their sales
                        </p>
                    </div>
                    {error && (
                        <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                            {error}
                        </div>
                    )}
                    <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                        <div className="flex-grow">
                            <label htmlFor="affiliateCode" className="hidden">
                                Affiliate's Code:
                            </label>
                            <input
                                type="text"
                                value={affiliateCode}
                                onChange={(e) => setAffiliateCode(e.target.value)}
                                placeholder="Enter Affiliate Code"
                                className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    {searchExecuted && tableData.length === 0 && (
                        <div>
                            <p className="mt-4">No sales data available for the selected criteria.</p>
                        </div>
                    )}

                    {searchExecuted && affiliateEmail !== "" && (
                        <AffiliateDetails
                            affiliateEmail={affiliateEmail}
                            affiliateName={affiliateName}
                            deepLinkUrl={deepLinkUrl}
                            localAffiliateOfferCode={localAffiliateOfferCode}
                            handleChange={handleChange}
                            handleCreate={handleCreate}
                            localCompanyOfferCouponCodes={localCompanyOfferCouponCodes}
                            selectedCode={selectedCode}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {searchExecuted && tableData.length > 0 && (
                        <AffiliateSalesTransactions
                            tableData={tableData}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            companyAffiliatePaymentPercentage={companyAffiliatePaymentPercentage}
                            transactionsAffiliateHasBeenPaidFor={transactionsAffiliateHasBeenPaidFor}
                            setTransactionsAffiliateHasBeenPaidFor={setTransactionsAffiliateHasBeenPaidFor}
                            transactionsAffiliateHasNotBeenPaidFor={transactionsAffiliateHasNotBeenPaidFor}
                            setTransactionsAffiliateHasNotBeenPaidFor={setTransactionsAffiliateHasNotBeenPaidFor}
                            affiliateEmail={affiliateEmail}
                            user={user}
                            totalAffiliateHasBeenPaid = {totalAffiliateHasBeenPaid}
                            setTotalAffiliateHasBeenPaid = {setTotalAffiliateHasBeenPaid}
                            totalAffiliateHasNotBeenPaid = {totalAffiliateHasNotBeenPaid}
                            setTotalAffiliateHasNotBeenPaid = {setTotalAffiliateHasNotBeenPaid}
                        />
                    )}
                </div>
            </div>
        </Container>
    );
};
export default AffiliateSearch;
