// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDeceXSdVpe4TmCdpI6iiSMoX-5rzVzmHA",
  authDomain: "projectiaa-b4f07.firebaseapp.com",
  projectId: "projectiaa-b4f07",
  storageBucket: "projectiaa-b4f07.appspot.com",
  messagingSenderId: "877759448179",
  appId: "1:877759448179:web:f6f4947da85f500fb39606",
  measurementId: "G-M1GCJVWKTP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

// Export the variables
export { app, analytics, firebaseConfig, db };
